.gredient-info-bg {
  background: linear-gradient(to right, #41a6f6 0%, #425be0 100%);
}

.product-sales {
  .c3-shape {
    stroke: transparent !important;
    stroke-width: 3px;
  }
}

/*******************
Earnings
*******************/

.earnings {
  position: relative;
  height: 250px;

  .ct-series-a .ct-area {
    fill-opacity: 0.2;
    fill: url(#gradient);
  }

  .ct-series-a .ct-line,
  .ct-series-a .ct-point {
    stroke: $info;
    stroke-width: 2px;
  }

  .ct-series-b .ct-area {
    fill: $cyan;
    fill-opacity: 0.1;
  }

  .ct-series-b .ct-line,
  .ct-series-b .ct-point {
    stroke: $cyan;
    stroke-width: 2px;
  }

  .ct-series-a .ct-point,
  .ct-series-b .ct-point {
    stroke-width: 6px;
  }
}

.ct-grid {
  stroke-width: 0.3px;
  stroke-dasharray: 0px;
}

.ct-series-a .ct-bar {
  stroke: $info;
}

@media (max-width: 1023px) {
  .gredient-info-bg {
    .info {
      margin-top: 15px;
    }
  }
}
