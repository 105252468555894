// In this scss you can overwrite all our scss, we suggested you to add your scss into this, so whenever the update comes it will not affect your code.

.modal-xxl {
  width: 95% !important;
  height: 95% !important;
}

.capitalizar {
  text-transform: capitalize;
}
