/*
Template Name: Admin Template
Author: Wrappixel

File: scss
*/
$mailbox-width:300px;
$mailbox-height:317px; 
$droptitle-bg: url(../../assets/images/background/img5.png) no-repeat;

/*******************
/*User mail widgets*/
/*******************/
.mailbox {
    
        .drop-title {
            font-weight: $font-weight-medium;
            padding: 11px 20px 15px;
            border-radius: $border-radius $border-radius 0 0;
            position: relative;
            &:after{
                content: "";
                position: absolute;
                background: $droptitle-bg;
                opacity: 0.2;
                top:0px;
                left:0px;
                height: 100%;
                width:100%;
                background-size: cover;
            }
        }
        .nav-link {
            border-top: 1px solid $border-color;
            padding-top: 15px;
            color:$body-color;
        }
    
    .message-center {
        height:$mailbox-height;
        overflow: auto;
        position: relative;
        .message-item {
            border-bottom: 1px solid $border-color;
            display: block;
            text-decoration: none;
            padding: 9px 15px;
            cursor: pointer;
            &:hover, &.active {
                background: $gray-100;
            }
            .message-title{
                color:$gray-900;
            }
            .user-img {
                width: 40px;
                position: relative;
                display: inline-block;
                margin: 0 0px 15px 0;
                img {
                    width: 100%;
                }
                .profile-status {
                    border: 2px solid $white;
                    border-radius: 50%;
                    display: inline-block;
                    height: 10px;
                    left: 30px;
                    position: absolute;
                    top: 1px;
                    width: 10px;
                }
                .online {
                    background: $success;
                }
                .busy {
                    background: $danger;
                }
                .away {
                    background: $warning;
                }
                .offline {
                    background: $warning;
                }
            }
            .mail-contnet {
                display: inline-block;
                width: 75%;
                padding-left: 10px;
                vertical-align: middle;
                .message-title {
                    margin: 5px 0px 0;
                }
                .mail-desc,
                .time {
                    font-size: 12px;
                    display: block;
                    margin: 1px 0;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    color: $text-muted; 
                    white-space: nowrap;
                }
            }
        }
    }
}

.mail-pagination .pagination {
    margin-bottom: 0;
    .page-link {
        padding: 7px 12px;
    }
}

@include media-breakpoint-down(md) {
    .detail-part {
        display: none;
    }
    .detail-part.movetodetail {
        display: block;
        position: absolute;
        background: $white;
        z-index: 9;
    }
}
